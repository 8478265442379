var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.registro != null)?_c('v-card',{staticClass:"mx-auto v-card--material pa-3",attrs:{"elevation":"0","loading":_vm.loading}},[_c('base-material-card',{attrs:{"icon":_vm.icon,"title":_vm.title,"color":_vm.color}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.subtitle))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('base-total-card',{attrs:{"item":{
					color: 'grey',
					icon: 'mdi-account-group',
					grupo: 'Total',
					cantidad: _vm.registro.total
				}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('base-total-card',{attrs:{"item":{
					color: 'grey',
					icon: 'mdi-account-circle',
					grupo: 'Miembros',
					cantidad: _vm.registro.totalMiembros
				}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('base-total-card',{attrs:{"item":{
					color: 'grey',
					icon: 'mdi-account-circle-outline darken-1',
					grupo: 'No Miemb.',
					cantidad: _vm.registro.totalNoMiembros
				}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('base-total-card',{attrs:{"item":{
					color: 'grey',
					icon: 'mdi-account-convert',
					grupo: 'Otra Especi.',
					cantidad: _vm.registro.otrasEspecialidades
				}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('base-total-card',{attrs:{"item":{
					color: 'grey',
					icon: 'mdi-account-settings-outline',
					grupo: 'Estudiantes',
					cantidad: _vm.registro.estudiantes
				}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('base-total-card',{attrs:{"item":{
					color: 'grey',
					icon: 'mdi-account-cog-outline',
					grupo: 'Industria',
					cantidad: this.registro.industria
				}}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }